<template>
    <b-modal id="clock-modal" hide-footer size="xl">
        <template #modal-title
            >Conversatii anterioare pe adresa de email curenta</template
        >
        <div class="d-block text-center">
            <v-data-table
                :headers="headers"
                :items="mails"
                :items-per-page="10"
                mobile-breakpoint="768"
            >
                <template v-slot:item.number="{ item }">
                    <v-chip :color="lastDays(item.createdDate)" dark x-small>
                        {{ item.number }}
                    </v-chip>
                </template>

                <template v-slot:item.customer.email="{ item }">
                    <ConversationLink :item="item" :linktext="item.customer.email" />
                </template>

                <template v-slot:item.statusName="{ item }">
                    <v-chip :color="statusColor[item.status]" dark x-small>
                        {{ item.statusName }}
                    </v-chip>
                </template>

                <template v-slot:item.updatedAt="{ item }">
                    <!-- <Date :date="item.updatedAt" /> -->
                    {{ formatDate(item.updatedAt, true) }}
                </template>
            </v-data-table>
        </div>
    </b-modal>
</template>

<script>
import moment from "moment";
import ConversationLink from "@/view/pages/conversation/ConversationLink";

export default {
    data() {
        return {
            headers: [
                {
                    text: "Nr.",
                    align: "left",
                    sortable: false,
                    value: "number",
                    class: "h6 grey lighten-2",
                },
                {
                    text: "Email client",
                    align: "left",
                    sortable: false,
                    value: "customer.email",
                    class: "h6 grey lighten-2",
                },
                {
                    text: "Subiect",
                    value: "subject",
                    sortable: false,
                    class: "h6 grey lighten-2",
                },
                {
                    text: "Departament",
                    value: "departament",
                    sortable: false,
                    class: "h6 grey lighten-2",
                },
                {
                    text: "Agent",
                    value: "assignee.firstName",
                    sortable: false,
                    class: "h6 grey lighten-2",
                },
                { text: "Status", value: "statusName", class: "h5 grey lighten-2" },
                {
                    text: "Data ultimei modificari",
                    value: "updatedAt",
                    class: "h6 grey lighten-2",
                },
            ],
            statusColor: {
                spam: "#f44336",
                active: "#f3c200",
                open: "#8950FC",
                pending: "#5867dd",
                closed: "#1BC5BD",
            },
            fourdaysago: moment().subtract(4, "d").format("YYYY-MM-DD"),
        };
    },
    props: ["mails"],
    components: {
        ConversationLink,
    },
    methods: {
        formatDate(date_created, time = false) {
            const date = new Date(date_created);

            return `${("0" + date.getDate()).slice(-2)}-${(
                "0" +
                (date.getMonth() + 1)
            ).slice(-2)} ${date.getFullYear()} ${
                time ? date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2) : ""
            }`;
        },
        closeModal() {
            this.$bvModal.hide("clock-modal");
        },
        lastDays(date) {
            return moment(date).isAfter(this.fourdaysago) ? "#f44336" : false;
        },
    },
};
</script>
