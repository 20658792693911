<template>
    <v-data-table
        :page="page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="customers"
        :options.sync="options"
        :server-items-length="totalCustomers"
        item-key="message"
        class="elevation-1 rounded-t-lg"
        id="contacts-list-messages"
        :footer-props="{ 'items-per-page-options': [10, 15, 25] }"
        :loading="loading"
        loading-text="Se incarca mail-uri... "
    >
        <template v-slot:top>
            <v-card>
                <v-card-title>
                    Contacte
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        label="Introdu contact"
                        single-line
                        hide-details
                    ></v-text-field>
                    <v-btn @click="searchCustomers" class="mr-1"> Cauta </v-btn>
                    <v-btn @click="resetSearch"> Reset </v-btn>
                    <v-spacer></v-spacer>
                    <AddGeneralRule />
                </v-card-title>
            </v-card>
            <LogTicketModal :mails="mails" />
            <WorkflowDialog
                :workflows="workflows"
                :activeWorkflows="activeWorkflows"
                @reload-conversation="reloadContacts"
            />
        </template>

        <template v-slot:item.name="{ item }">
            {{ fullName(item) }}
        </template>
        <template v-slot:item.email="{ item }">
            {{ item._embedded.emails[0].value }}
        </template>
        <template v-slot:item.workflows="{ item }">
            <span
                v-if="item._embedded.workflows.length"
                class="svg-icon svg-icon-2x"
                :class="{
                    'svg-icon-danger': catchByRule(item),
                    'svg-icon-secondary': !catchByRule(item),
                }"
                @click="openWorkflow(item)"
            >
                <!-- <WorkflowButton :workflows="item._embedded.workflows" /> -->
                <inline-svg src="/media/svg/icons/Text/Filter.svg" />
            </span>
        </template>
        <template v-slot:item.conversations="{ item }">
            <b-button
                v-if="item.hasConversations"
                @click="openClockModal(item)"
                :id="'tooltip-' + item.id"
                class="mr-1"
                :class="{
                    'btn-danger': item.hasConvsInLast4Days,
                }"
            >
                <!-- <b-button @click="openClockModal(item)" :key="item.id" class="mr-1"> -->
                <b-icon icon="clock"></b-icon>
            </b-button>
            <!-- <b-tooltip
                v-if="!item.hasConversations"
                :target="'tooltip-' + item.id"
                triggers="hover"
            >
                Inca nu sunt conversatii pe acest contact!
            </b-tooltip> -->
        </template>
    </v-data-table>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import LogTicketModal from "@/view/pages/conversation/LogTicketModal";
import WorkflowDialog from "@/view/pages/conversation/workflows/WorkflowDialog";
import moment from "moment";
import { CLIENT_DIALOG } from "@/core/services/store/modules/workflow";
import AddGeneralRule from "@/view/pages/conversation/AddGeneralRule";

export default {
    data: () => ({
        page: 1,
        numberOfPages: 0,
        options: {
            itemsPerPage: 10,
        },
        totalCustomers: 0,
        loading: false,
        search: "",
        headers: [
            {
                text: "Nume",
                sortable: false,
                value: "name",
                width: "40%",
                class: "h6 grey lighten-2",
            },
            {
                text: "Email",
                value: "email",
                width: "40%",
                sortable: false,
                class: "h6 grey lighten-2",
            },
            {
                text: "Workflows",
                value: "workflows",
                align: "center",
                width: "5%",
                sortable: false,
                class: "h6 grey lighten-2",
            },
            {
                text: "Conversatii",
                value: "conversations",
                align: "center",
                width: "5%",
                sortable: false,
                class: "h6 grey lighten-2",
            },
        ],
        dialog: false,
        itemIndex: -1,
        item: {},
        mails: [],
        workflows: [],
        activeWorkflows: [],
    }),

    components: { LogTicketModal, WorkflowDialog, AddGeneralRule },

    watch: {
        options: {
            handler() {
                this.getData();
            },
            deep: true,
        },
    },

    computed: {
        customers() {
            return this.$store.getters["customers/customers"].customers;
        },
    },
    methods: {
        getData() {
            this.loading = true;

            let payload = {
                // search: this.form.searchkey,
                per_page: this.options.itemsPerPage,
                page: this.options.page,
            };

            if (this.search) {
                payload.keyword = this.search;
                this.$store
                    .dispatch("customers/searchCustomers", payload)
                    .then((response) => {
                        if (response) {
                            this.loading = false;
                            this.totalCustomers = response.page.totalElements;
                            this.numberOfPages = response.page.totalPages;
                        } else {
                            setTimeout(() => {
                                this.loading = false;
                            }, 500);
                        }
                    });
            } else {
                this.$store
                    .dispatch("customers/getCustomers", payload)
                    .then((response) => {
                        if (response) {
                            this.loading = false;
                            this.totalCustomers = response.page.totalElements;
                            this.numberOfPages = response.page.totalPages;
                        } else {
                            setTimeout(() => {
                                this.loading = false;
                            }, 500);
                        }
                    });
            }
        },
        openRules() {
            this.$store.dispatch(CLIENT_DIALOG, true);
        },
        fullName(item) {
            let fullName = "";
            fullName += item.firstName ? item.firstName : "";
            fullName += item.lastName ? " " + item.lastName : "";
            return fullName;
        },
        openClockModal(item) {
            this.editedIndex = this.customers.indexOf(item);
            this.editedItem = Object.assign({}, item);

            let payload = {
                customerEmail: item._embedded.emails[0].value,
            };

            this.$store.dispatch("mails/getEmails", payload).then((response) => {
                this.mails = response;
            });

            if (item.hasConversations) {
                this.$bvModal.show("clock-modal");
            }
        },
        searchCustomers() {
            this.resetPage();
            this.getData();
        },
        resetPage() {
            if (this.options.page !== 1) {
                this.options.page = 1;
            }
        },
        resetSearch() {
            this.resetPage();
            this.search = "";
            this.getData();
        },
        openWorkflow(item) {
            this.editedIndex = this.customers.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.workflows = this.editedItem._embedded.workflows;
            this.workflows.forEach(workflow => {
                if(workflow._embedded.workflow?.active === 1) {
                        this.activeWorkflows.push(workflow);
                    }
            });
            this.openRules();
        },
        catchByRule(item) {
            let isActiveRule = false;
            if (item._embedded.workflows) {
                item._embedded.workflows.map((workflow) => {
                    if (
                        workflow._embedded.workflow !== null &&
                        workflow._embedded.workflow.active
                    ) {
                        isActiveRule = true;
                        return;
                    }
                });
            }
            // console.log(isActiveRule);
            return isActiveRule;
        },
        reloadContacts() {
            this.getData();
            this.activeWorkflows = [];
        },
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Mail Pefoc.ro - Lista contacte" },
        ]);
    },
    created() {
        // // console.log("deprtaments", this.departments.length);
        // if (!this.$store.getters["departments/allDepartments"].length) {
        //     // console.log("intra");
        //     this.$store.dispatch("departments/getPasareDepartments");
        // }
        // this.getData();
    },
};
</script>

<style>
#contacts-list-messages td {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
}

#contacts-list-messages tr {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: #eff2f5;
}

#contacts-list-messages .top {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    min-height: 70px;
    padding: 0 2.25rem;
    background-color: transparent;
    border-bottom: 1px solid #eff2f5;
}

#contacts-list-messages tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}
</style>
