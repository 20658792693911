<template>
    <v-dialog v-model="dialog" @click:outside="close" @keydown.esc="close">
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Reguli pentru adresa curenta
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <WorkflowRules
                    v-if="workflows"
                    :workflows="workflows"
                    @reload-conversation="reloadConversation"
                    :page="page"
                />
            </v-card-text>
            <v-card-actions>
                <DeleteAllWorkflowsButton
                    :workflow_exists="workflows"
                    path="workflowDialog"
                    @workflow-deleted="reloadConversation"
                    v-if="activeWorkflows.length > 1"
                />
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close"> Ok </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import WorkflowRules from "@/view/pages/conversation/workflows/WorkflowRules";
import { CLIENT_DIALOG } from "@/core/services/store/modules/workflow";
import DeleteAllWorkflowsButton from "@/view/content/buttons/DeleteAllWorkflowsButton.vue";

export default {
    props: ["workflows", "activeWorkflows"],
    data() {
        return {
            page: 1,
        };
    },
    components: {
        WorkflowRules,
        DeleteAllWorkflowsButton,
    },
    computed: {
        dialog() {
            return this.$store.state.workflow.clientDialog;
        },
    },
    watch: {},
    methods: {
        close() {
            this.$store.dispatch(CLIENT_DIALOG, !this.$store.state.workflow.clientDialog);
            this.page = 1;
            // console.log(this.page);
        },
        reloadConversation() {
            this.close();
            this.$emit("reload-conversation", true);
        },
    },
};
</script>
