<template>
    <div>
        <v-btn color="red darken-1" v-if="this.workflow_exists.length > 0" text @click="deleteAllWorkflows(workflow_exists, path)"> STERGE TOATE REGULILE
        </v-btn>
    </div>
</template>
    
<script>
import { DELETE_WORKFLOW } from "@/core/services/store/modules/workflow";

export default {
    props: ["workflow_exists", "path"],
    methods: {
        deleteAllWorkflows(workflow_exists, path) {
            let workflowsIds = [];
            if (path === 'addGeneralRule' || path === 'addRuleButton') {
                workflowsIds = workflow_exists.map((workflow) => {
                    return workflow.id;
                });
            } else {
                workflow_exists.filter((i) => {
                    if(i._embedded.workflow) {
                        workflowsIds.push(i._embedded.workflow.id);
                    }
                return workflowsIds; 
                });
            }
            this.$emit("workflow-deleted", workflowsIds);

            this.$store.dispatch(DELETE_WORKFLOW, workflowsIds).then(() => {
                this.$store.dispatch("mails/getWorkflows");
            });
        }
    },
}
</script>