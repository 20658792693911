<template>
    <v-data-table
        :headers="headers"
        :items="filteredItems"
        @update:page="updatePagination"
        item-key="_embedded.workflow.id"
        :page="page"
        class="elevation-1"
    >
        <template v-slot:top>

            <EditRuleDialog
                :showItem="showItem"
                :dialog="dialog"
                @close-dialog="closeDialog"
                @workflow-deleted="reloadConversation"
            />
        </template>
        <template v-slot:item.status="{ item }">
            <v-chip
                :color="item._embedded.workflow.active ? 'green' : 'red'"
                dark
                x-small
            >
                {{ item._embedded.workflow.active ? "Activa" : "Inactiva" }}
            </v-chip>
            <button
                @click="showWorkflow(item)"
                type="button"
                class="btn btn-outline-primary btn-sm font-weight-bolder font-size-sm py-2 ml-3"
            >
                vezi regula
            </button>
        </template>
        <template v-slot:no-data>
            <div class="text-primary">Nu exista mail-uri</div>
        </template>
    </v-data-table>
</template>

<script>
import EditRuleDialog from "@/view/pages/workflows/EditRuleDialog.vue";
export default {
    props: ["workflows", "page"],
    data() {
        return {
            headers: [
                {
                    text: "Regula",
                    align: "start",
                    sortable: false,
                    value: "_embedded.workflow.name",
                    class: "h6 grey lighten-2",
                },
                {
                    text: "Departament",
                    value: "_embedded.workflow.mailbox",
                    sortable: false,
                    class: "h6 grey lighten-2",
                    width: "20%",
                },
                {
                    text: "Status",
                    value: "status",
                    sortable: false,
                    class: "h6 grey lighten-2",
                },
            ],
            dialog: false,
            showItem: {},
        };
    },
    components: {
        EditRuleDialog,
    },
    computed: {
        filteredItems() {
            return this.workflows.filter((i) => {
                // console.log(i);
                return i._embedded.workflow ? true : false;
            });
        },
    },
    methods: {
        showWorkflow(item) {
            // this.showItem = this.workflows.indexOf(item);
            this.showItem = Object.assign({}, item._embedded.workflow);
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        reloadConversation() {
            this.dialog = false;
            this.$emit("reload-conversation", true);
        },
        updatePagination(val) {
            // console.log("test pagination", val);
            this.page = val;
            // console.log("page", this.page);
        },
    },
};
</script>
