<template>
    <v-dialog v-if="showItem.conditions" v-model="dialog" max-width="500px">
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    <span class="text-muted">Vizualizare regula </span> <br />
                    {{ showItem.name }}
                    <br />
                    <v-chip :color="showItem.active ? 'green' : 'red'" dark x-small>
                        {{ showItem.active ? "activ" : "inactiv" }}
                    </v-chip>
                </span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <div class="font-weight-bold text-h6">Conditii</div>
                            <div
                                v-for="(subconditions, i) in showItem.conditions"
                                :key="i"
                                class="text-body-1"
                            >
                                <div
                                    v-for="(cond, index) in subconditions"
                                    :key="cond.type + index"
                                >
                                    {{ cond.text }}
                                    <span
                                        v-if="index < subconditions.length - 1"
                                        class="green--text"
                                    >
                                        SAU
                                    </span>
                                </div>
                                <div
                                    v-if="i < showItem.conditions.length - 1"
                                    class="red--text"
                                >
                                    SI
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div class="font-weight-bold text-h6">Actiuni</div>
                            <div
                                v-for="(subactions, i) in showItem.actions"
                                :key="i"
                                class="text-body-1"
                            >
                                <div
                                    v-for="(action, index) in subactions"
                                    :key="action.type + index"
                                >
                                    {{ action.text }}
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div class="font-weight-bold text-h6">Creata de</div>
                            <div class="text-body-1">
                                {{
                                    showItem.created_by
                                        ? showItem.created_by.fullName
                                        : "Neprecizat"
                                }}
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red darken-1"
                    text
                    @click="deleteRule(showItem)"
                    v-if="showItem.active"
                >
                    Sterge
                </v-btn>
                <!-- <v-btn color="green darken-1" text @click="deleteRule(showItem)">
                    Editeaza
                </v-btn> -->
                <v-btn color="blue darken-1" text @click="closeDialog"> Ok </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { DELETE_WORKFLOW } from "@/core/services/store/modules/workflow";

export default {
    data() {
        return {};
    },
    props: ["showItem", "dialog"],
    watch: {},
    computed: {},
    methods: {
        closeDialog() {
            this.$emit("close-dialog", this.dialog);
        },
        deleteRule(rule) {
            this.$store.dispatch(DELETE_WORKFLOW, rule.id).then(() => {
                this.$store.dispatch("mails/getWorkflows");
                this.$emit("workflow-deleted", rule);
                // this.dialog = false;
            });
        },
    },
    mounted() {},
};
</script>
<style scoped>
.v-dialog__content--active {
    z-index: 99999999 !important;
}
</style>
